<template>
  <div ref="root" :style="style"></div>
</template>

<script>
import echarts from "../charts";
export default {
  props: { control: Object, isView: Boolean },
  data() {
    return {
      chart: null,
    };
  },
  computed: {
    style() {
      let result = JSON.parse(JSON.stringify(this.control.style));
      delete result["color"];
      return result;
    },
  },
  mounted() {
    this.change();
  },
  beforeDestroy() {
    if (this.chart) {
      let dom = this.chart.getDom();
      this.chart.dispose();
      if (dom) this.$refs.root.removeChild(dom);
      this.chart = null;
    }
  },
  watch: {
    "control.size": {
      handler() {
        this.change();
      },
      deep: true,
    },
    "control.style": {
      handler() {
        this.change();
      },
      deep: true,
    },
    "control.config": {
      handler() {
        this.change();
      },
      deep: true,
    },
  },
  methods: {
    change() {
      if (!this.chart) {
        let div = document.createElement("div");
        this.$refs.root.appendChild(div);
        this.chart = echarts.init(div, null, {
          width: this.control.size.width,
          height: this.control.size.height,
        });
      } else {
        this.chart.resize({
          width: this.control.size.width,
          height: this.control.size.height,
        });
      }
      let xData = this.control.config.xData || "Mon,Tue,Wed,Thu,Fri,Sat,Sun";
      let yData = this.control.config.yData || "120,200,150,80,70,110,130";
      this.chart.setOption(
        {
          grid: { top: 24, right: 24, bottom: 48, left: 48 },
          tooltip: {
            trigger: "item",
            formatter: "{b}<br/>{c}",
          },
          xAxis: {
            type: "category",
            axisLine: { lineStyle: { color: this.control.style.color } },
            data: xData.toString().split(","),
          },
          yAxis: {
            type: "value",
            axisLine: { lineStyle: { color: this.control.style.color } },
            splitLine: { lineStyle: { color: this.control.style.color } },
          },
          series: [
            {
              type: "bar",
              itemStyle: { color: this.control.style.color },
              data: yData.toString().split(","),
            },
          ],
        },
        !this.isView
      );
    },
  },
};
</script>
